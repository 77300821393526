
import React from "react"

import ProductBasicEditorPage from "../../components/negosyonow/controls/productbasiceditor"

const webappsAPI = require("../../../lib/requestWebapps");


const nnproductaliaspickerfields = [
	{"label":"Product Line", "dbfield": "nnproductalias_name", "type": "text", "filtertype": "textbox"},
	{"label":"Product Name", "dbfield": "nnproductalias_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nnproductalias_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproductalias_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Last Update", "dbfield": "nnproductalias_lastupdate", "type": "datetime", "filtertype": "datetime"},
];

const nnproductaliaspickerfilter = [
];


const nntagpickerfields = [
	{"label":"Name", "dbfield": "nntag_name", "type": "text", "filtertype": "textbox"},
];

const nntagpickerfilter = [
	{"field":"nntagalias_id", "operation": "<>", "value": "48"},	// Page
	{"field":"nntagalias_id", "operation": "<>", "value": "1"},		// Brand
	{"field":"nntag_id", "operation": "<>", "value": "99"},			// Promo
	{"field":"nntag_official", "operation": "=", "value": "1"},
	{"field":"nntag_url", "operation": "<>", "value": ""}
];


const searchFields = [
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproduct_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Full Name / Display", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Active", "dbfield": "nnproduct_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Permanently Delisted/Internal Use", "dbfield": "nnproduct_internal", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Price", "dbfield": "nnproduct_price", "type": "currency", "filtertype": "currency"},

	{"label":"Brand", "dbfield": "nnbrand_name", "type": "text", "filtertype": "textbox"},
	{"label":"Product Line", "dbfield": "nnproductalias_name", "type": "text", "filtertype": "textbox"},

	{"label":"Last Update", "dbfield": "nnproduct_lastupdate", "type": "datetime", "filtertype": "datetime"},
];


const basicFields = [
	{"label":"Primary Image", "field": "nnproduct_image", "value": "", "input": "image", "mode": "readonly"},
	{"label":"Seller SKU", "field": "nnproduct_orgsku", "value": "", "input": "textbox", "mode": "editonce"},
	{"label":"Full Name / Display", "field": "nnproduct_fullname", "value": "", "input": "textbox", "mode": "required"},

	{"label":"Parent Product", "field": "nnproductalias_fullname", "value": "", "input": "picker", "mode": "required",  "pickerfields": nnproductaliaspickerfields, "pickerfilters": nnproductaliaspickerfilter},

	{"label":"Promo Option Display", "field": "nnproduct_subname", "value": "", "input": "textbox", "mode": "normal", "info":"Displayed under product promo options in website"},
	{"label":"Promo Item", "field": "nnproduct_promoitem", "value": "1", "input": "hidden", "mode": "normal"},

	{"label":"Has Page", "field": "nnproduct_haspage", "value": "", "input": "hidden", "mode": "readonly"},
	{"label":"Webpage", "field": "nnproduct_actualurl", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/"},
	{"label":"Last Updated", "field": "nnproduct_lastupdate", "value": "", "input": "updatetime", "mode": "readonly"},
	{"label":"Active", "field": "nnproduct_active", "value": "", "input": "checkbox", "mode": "normal"},
	{"label":"Price", "field": "nnproduct_price", "value": "", "input": "currency", "mode": "required"},

	{"label":"Promo Image", "field": "nnproduct_promoimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":500, "imageheight":400, "imageformat":"jpg", "uploadtype":"https", "filehost": "cdn.ngnw.ph", "filelocation": "images/product/promo", "info":"For website promo display area"},

	{"label":"Write-up", "field": "nnproduct_description", "value": "", "input": "textarea", "mode": "normal"},
	{"label":"Teaser/Summary", "field": "nnproduct_summary", "value": "", "input": "textarea", "mode": "normal"},
];

const subformFields = [
	{
		"subformid": "nnproductimage",
		"label": "Images",
		"table": "nnproductimage",
		"sort": "nnproductimage_primary desc",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Image",
				"field": "nnproductimage_url",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "required",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "images/product/main/full", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "fit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 2048,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 2048,	// OPTIONAL: Will be required for some imagemode
			},
			{
				"label": "Description",
				"field": "nnproductimage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
			{
				"label": "Main Imaage",
				"field": "nnproductimage_primary",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal",
				"info": "Only one main image"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnproducttag",
		"label": "Categories",
		"table": "nnproducttag",
		"sort": "nnproducttag_id ",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Category",
				"field": "nntag_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nntagpickerfields,
				"pickerfilters": nntagpickerfilter
			}
		],
		"footerlist": [
		]
	}
];

const ProductPage = ({location}) => {

	function customPostprocessing(pkid, token, callback)
	{
		webappsAPI.saveRecord("nnproduct", {
			"documentid":pkid,
			"nnproduct_promoitem":1
		}, token).then(response => {
			callback();
		});
	}

	return <ProductBasicEditorPage
				allowadd={true}
				location={location}
				formFields={[basicFields]}
				searchFields={searchFields}
				editSubFormFields={subformFields}
				customSearchFilter={[{"field":"nnproduct_promoitem", "operation":"=", "value": 1}]}
				customPostprocessing={customPostprocessing}
			/>
}


export default ProductPage;
